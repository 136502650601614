import { Select } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unitActions } from '../../actions';

const SelectSWVersion = (props) => {
  const dispatch = useDispatch();
  const { selectedSWversionId } = useSelector((state) => state.unit);
  const { unit, unitSn, swOptions } = props;

  const isVersionExist = swOptions.some(
    (option) => option.versionNumber === unit.sw_version,
  );
  const updatedSwOptions = !isVersionExist
    ? [
        ...swOptions,
        {
          versionNumber: unit.sw_version,
          description: 'Online branch version',
          deprecated: null,
          releaseNotesUrl: null,
        },
      ]
    : swOptions;

  useEffect(() => {
    dispatch(unitActions.storeSelectedSWVersionId(unit.sw_version));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeSWVersion = (val) => {
    if (val) {
      dispatch(unitActions.storeSelectedSWVersionId(val));
    }
  };

  return (
    <span className='editUnitName d-flex flex-row align-items-center mt-1'>
      <div className='smalltxt mb-1' style={{ whiteSpace: 'nowrap' }}>
        {unitSn} -
      </div>
      <div style={{ flex: 0.55, minWidth: '0' }}>
        <Select
          className='swVersion w-100'
          name='swVersion'
          value={selectedSWversionId}
          onChange={(value) => onChangeSWVersion(value)}
          onBlur={() => {
            dispatch(unitActions.onTouchSwVersionSelectField(true));
          }}
        >
          {updatedSwOptions.map(({ versionNumber }) => (
            <Select.Option value={versionNumber} key={versionNumber}>
              {versionNumber}
            </Select.Option>
          ))}
        </Select>
      </div>

      <button
        type='button'
        className='btn btn-danger btn-sm btn-no mx-1 py-1 px-2'
        onClick={() => {
          props.cancelRenameUnit(unit);
          dispatch(unitActions.storeSelectedSWVersionId(null));
        }}
      >
        <i className='fa fa-times'></i>
      </button>
    </span>
  );
};

export default SelectSWVersion;
