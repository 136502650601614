import i18n from 'i18next';
import React, { Component } from 'react';
import { notify } from '../CommonNotification/CommonNotification';

export class EditUnitName extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: this.props.unit.name,
      unitProduct: this.props.unit.product,
    };
  }

  renameUnitHandler = (unit) => {
    const currentUnitName = this.state.name.trim();
    const preUnitName = this.props.unit.name.trim();
    const {
      isSwVersionSelectFieldTouch,
      renameUnit,
      onClickWithoutChangeSWVersion,
    } = this.props;

    const payload = {
      unit: { name: this.state.name },
    };
    unit.name = this.state.name;
    if (preUnitName !== currentUnitName) {
      renameUnit(unit.BOSSID, payload, unit);
      notify('success', i18n.t('SUCCESSUNITNAMECHANGE'));
    }
    if (isSwVersionSelectFieldTouch) {
      this.changeUnitSwVersion();
    }
    onClickWithoutChangeSWVersion(unit);
  };

  changeUnitSwVersion = () => {
    if (this.props.sw_version !== this.props.selectedSWversionId) {
      this.props.onSaveUpdateSwVersion(
        this.props.unit.BOSSID,
        this.props.selectedSWversionId,
        this.props.unit,
      );
      notify('success', i18n.t('SUCCESSSWVERSION'));
    } else {
      notify('warning', i18n.t('SAME_VERSION_WARNING'));
    }
    this.props.onTouchSwVersionSelectField(false);
  };

  unitNameChangeHandler = (e) => {
    this.setState({ name: e.target.value });
  };

  cancelRenameHandler = () => {
    this.props.cancelRenameUnit(this.props.unit);
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    // Only safe with condition
    if (prevProps.unit.BOSSID !== this.props.unit.BOSSID) {
      this.setState({ name: this.props.unit.name });
    }
  }

  render() {
    const { unitProduct } = this.state;
    return (
      <span className='editUnitName d-flex flex-row'>
        <input
          onChange={(e) => this.unitNameChangeHandler(e)}
          type='text'
          value={this.state.name}
          ng-required='true'
          className='h-28'
          //ng-pattern="/^\S+[a-zA-Z0-9_-]*?$/"
        />

        <button
          type='button'
          className='btn btn-primary mx-1 btn-sm btn-no py-0 px-2'
          onClick={() => this.props.removeUnit(this.props.unit)}
        >
          {i18n.t('REMOVEUNIT')}
        </button>
        <button
          type='button'
          className='btn btn-primary btn-sm btn-yes py-0 px-2'
          onClick={() => this.renameUnitHandler(this.props.unit)}
          disabled={!/^(\b[\wa-zA-Z0-9_-]{5,})+$/.test(this.state.name)}
        >
          {unitProduct && unitProduct.includes('LU_SOLO_PRO') ? (
            <i className='fa fa-check'></i>
          ) : (
            i18n.t('SAVE')
          )}
        </button>
        {unitProduct && unitProduct.includes('LU_SOLO_PRO') && (
          <button
            type='button'
            className='btn btn-danger btn-sm mx-1 btn-no py-0 px-2'
            onClick={() => this.cancelRenameHandler(this.props.unit)}
          >
            <i className='fa fa-times'></i>
          </button>
        )}
      </span>
    );
  }
}
