import axios from 'axios';
import Cookies from 'universal-cookie';
import { getLocalStorageItem } from '../utils/cookieAndLocalStorageUtils';
const cookies = new Cookies();
const { REACT_APP_LUCServerUrl } = process.env;

export const _rebootLUCUnit = (lucHeaders, unitBossId) =>
  axios.post(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v2/units/${unitBossId}/reboot`,
    {},
    { headers: lucHeaders },
  );

export const _registerLUCUser = (lucHeaders, user) =>
  axios.post(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v0/inventory/user`,
    user,
    { headers: lucHeaders },
  );
export const _verifyLUCUser = (lucHeaders, user) =>
  axios.post(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v0/inventory/user/verify`,
    user,
    { headers: lucHeaders },
  );
export const _resetLUCPassword = (lucHeaders, payload) =>
  axios.post(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v0/account/password/reset`,
    payload,
    { header: lucHeaders },
  );

export const _changeLUCPassword = (lucHeaders, payload) =>
  axios.put(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v0/account/password/update`,
    payload,
    { headers: lucHeaders },
  );
export const _updateLUCPassword = (lucHeaders, payload) =>
  axios.put(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v0/account/password`,
    payload,
    { header: lucHeaders },
  );
export const getLUCUserInventories = (lucHeaders) =>
  axios.get(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v2/inventories/?limit=1000`,
    {
      headers: lucHeaders,
    },
  );
export const getLUCUserDetails = (lucHeaders) =>
  axios.get(`${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v2/user`, {
    headers: lucHeaders,
  });
export const getLUCVideoStatus = (lucHeaders, id, path) =>
  axios.get(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v0/units/${id}/status/${path}`,
    { headers: lucHeaders },
  );
export const updateLUCUnitById = (lucHeaders, id, payload) =>
  axios.put(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v0/units/${id}`,
    payload,
    { headers: lucHeaders },
  );
export const getLUCUnitByUser = (lucHeaders) =>
  axios.get(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v0/inventories/${cookies.get(
      'inventory_id',
    )}/units/light`,
    {
      headers: lucHeaders,
      params: {
        soloOnly: true,
      },
    },
  );
export const getLUCUnitStatus = (lucHeaders, id) =>
  axios.get(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v0/units/${id}/status`,
    { headers: lucHeaders },
  );
export const deleteLUCdestination = (lucHeaders, destId) =>
  axios.delete(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v2/destinations/${destId}`,
    { headers: lucHeaders },
  );
export const _getLUCDestination = (lucHeaders, destId) =>
  axios.get(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v2/inventories/${cookies.get(
      'lucUserId',
    )}/destinations/${destId}`,
    { headers: lucHeaders },
  );
export const getLUCDestinations = (lucHeaders) => {
  return (
    getLocalStorageItem('access_token') &&
    axios.get(
      `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v2/inventories/${cookies.get(
        'lucUserId',
      )}/destinations`,
      { headers: lucHeaders },
    )
  );
};
/**
 * not use this function
 */
export const createLUCDest = (data, lucHeaders) =>
  axios.post(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v0/inventories/${cookies.get(
      'inventory_id',
    )}/destinations`,
    data,
    { headers: lucHeaders },
  );
export const setLucDes = (payload, unitId, lucHeaders) =>
  axios.put(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v0/units/${unitId}`,
    payload,
    { headers: lucHeaders },
  );
export const getLUCStreamingProviders = (lucHeaders) =>
  axios.get(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v0/streaming_providers`,
    { headers: lucHeaders },
  );
export const getUnitDelay = (payload, unitId, lucHeaders) =>
  axios.put(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v0/units/${unitId}/delay`,
    payload,
    { headers: lucHeaders },
  );
export const _createDestination = (lucHeaders, payload) =>
  axios.post(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v0/inventories/${cookies.get(
      'inventory_id',
    )}/destinations?overwrite=true`,
    payload,
    { headers: lucHeaders },
  );
export const _startUnit = (lucHeaders, id, payload) =>
  axios.post(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v0/units/${id}/stream`,
    payload,
    { headers: lucHeaders },
  );
export const _stopUnit = (lucHeaders, id) =>
  axios.delete(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v0/units/${id}/stream`,
    { headers: lucHeaders },
  );
export const _changeZone = (lucHeaders, id, payload) =>
  axios.put(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v0/units/${id}`,
    payload,
    { headers: lucHeaders },
  );
export const _getChannelServicesAndDestination = (lucHeaders, id) =>
  axios.get(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v0/units/${id}?fields=channels,services,selected_channel,selected_destination`,
    { headers: lucHeaders },
  );
export const _editUsers = (lucHeaders, payload) =>
  axios.put(`${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v2/user`, payload, {
    headers: lucHeaders,
  });
export const _getUnitDetails = (lucHeaders, id) =>
  axios.get(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v0/units/${id}?fields=selected_destination`,
    { headers: lucHeaders },
  );
export const _registerLUCUnit = (lucHeaders, payload, id) =>
  axios.post(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v0/inventories/${id}/units?reg_code=${payload.reg_code}`,
    payload,
    { headers: lucHeaders },
  );
export const _getInterfaces = (lucHeaders, id) =>
  axios.get(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v0/units/${id}/status/interfaces`,
    { headers: lucHeaders },
  );
export const _getInterfaceDetails = (lucHeaders, id, path, port, subpath) =>
  axios.get(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v0/units/${id}/interfaces/${path}/${port}/${subpath}`,
    { headers: lucHeaders },
  );
export const _editInterfaceDetails = (
  lucHeaders,
  payload,
  id,
  path,
  port,
  subpath,
) =>
  axios.put(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v0/units/${id}/interfaces/${path}/${port}/${subpath}`,
    payload,
    { headers: lucHeaders },
  );
export const _editWifiDetails = (
  lucHeaders,
  payload,
  id,
  path,
  port,
  subpath,
  ssid,
) =>
  axios.put(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v0/units/${id}/interfaces/${path}/${port}/${subpath}/${ssid}`,
    payload,
    { headers: lucHeaders },
  );

export const _removeUnit = (lucHeaders, id, payload) =>
  axios.post(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v2/units/${id}/deregistration`,
    payload,
    { headers: lucHeaders },
  );

export const _emailChangeRequest = (lucHeaders, payload) =>
  axios.post(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v2/users/details/request`,
    payload,
    { headers: lucHeaders },
  );
export const _emailChangeVerification = (lucHeaders, payload) => {
  return axios.post(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v2/users/details/verification`,
    payload,
    { headers: lucHeaders },
  );
};

export const _sendUnitLogs = (lucHeaders, id, payload) => {
  return axios.put(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v2/units/${id}/fetchlogs`,
    payload,
    { headers: lucHeaders },
  );
};

export const _clearAccessToken = (lucHeaders) => {
  return axios.delete(`${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/login`, {
    headers: lucHeaders,
  });
};

export const _swVersionList = (lucHeaders, id) => {
  return axios.get(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v2/units/${id}/swVersions`,
    { headers: lucHeaders },
  );
};

export const _updateSwVersion = (lucHeaders, id, version) => {
  return axios.put(
    `${REACT_APP_LUCServerUrl}luc/luc-core-web/rest/v2/units/${id}/targetswversion/${version}`,
    {},
    { headers: lucHeaders },
  );
};
